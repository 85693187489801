import Link from 'next/link'
import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'

import Input from '../components/Input'
import formStyles from '../components/Form.module.css'
// @ts-ignore
import PasswordField from '../components/PasswordField'
// @ts-ignore
import RawFormattedMessage from '../components/RawFormattedMessage'
import styles from './Login.module.css'
import { actionCreators } from '../login'
// @ts-ignore
import { resetPassword } from '../routes'
// @ts-ignore
import { State } from '../store/reducer'

interface StateToProps {
  isLoggedIn: boolean
}

interface DispatchToProps {
  loadAuthToken: typeof actionCreators.loadAuthToken
}

type LoginProps = StateToProps & DispatchToProps & WrappedComponentProps

export const Login: React.FC<LoginProps> = props => (
  <div className={styles.container}>
    <section className={styles.root}>
      <header>
        <RawFormattedMessage tagName='h4' id='login.title' />
      </header>
      <form
        className={formStyles.root}
        data-qa='login.form'
        onSubmit={e => {
          e.preventDefault()
          props.loadAuthToken()
        }}
      >
        <Input
          data-qa='username'
          id='email'
          label={props.intl.formatMessage({ id: 'login.username' })}
          required={true}
          type='email'
        />
        <PasswordField id='password' />
        <Link {...resetPassword()}>
          <a
            className={styles.link}
            data-type='tertiary'
            data-qa='forgot-your-password'
          >
            <FormattedMessage id='login.forgot-password' />
          </a>
        </Link>
        <br />
        <button type='submit' data-qa='login'>
          <FormattedMessage id='login.action' />
        </button>
      </form>
    </section>
  </div>
)

function mapStateToProps ({ login: { isLoggedIn } }: State): StateToProps {
  return {
    isLoggedIn
  }
}

function mapDispatchToProps (dispatch: Dispatch): DispatchToProps {
  return bindActionCreators(
    {
      loadAuthToken: actionCreators.loadAuthToken
    },
    dispatch
  )
}

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(Login)
