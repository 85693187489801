import { compose } from 'redux'
import Login from '../src/login/Login'
import withAuthentication from '../src/withAuthentication'
// @ts-ignore
import { HOME, permissions } from '../src/routes'

export default compose(withAuthentication(permissions[HOME]))(
  // @ts-ignore
  Login
)
