// @flow
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { PasswordInput } from './Input'

interface PropsType extends WrappedComponentProps {
  id: string
  label?: string
}

export const PasswordField = (props: PropsType) => (
  <PasswordInput
    data-qa='password'
    id={props.id}
    label={props.intl.formatMessage({ id: 'login.password' })}
    required={true}
  />
)

export default injectIntl(PasswordField)
